var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vertical_card" }, [
    _c(
      "div",
      {
        class: { search_card: true, isLast: (_vm.index + 1) % 5 == 0 },
        on: {
          click: function($event) {
            return _vm.toDetail(_vm.data.goodsId || _vm.data.id)
          }
        }
      },
      [
        _vm.data.specialText && _vm.data.specialText[0]
          ? _c(
              "span",
              {
                staticClass: "sp_text",
                attrs: {
                  title: _vm.data.specialText && _vm.data.specialText[0]
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.data.specialText && _vm.data.specialText[0]) +
                    " "
                )
              ]
            )
          : _vm._e(),
        _c("img", {
          staticClass: "main_img",
          attrs: { src: _vm.data.mainPic + "_310x310.jpg", alt: "商品主图" }
        }),
        _c("div", { staticClass: "card_content" }, [
          _c("div", { staticClass: "search_card_title" }, [
            _c("span", { style: { verticalAlign: "middle" } }, [
              _c("img", {
                attrs: {
                  src: _vm.getShopImg(
                    _vm.data.haitao,
                    _vm.data.tchaoshi,
                    _vm.data.shopType
                  ),
                  alt: ""
                }
              })
            ]),
            _c("span", [_vm._v(_vm._s(_vm.data.dtitle || _vm.data.title))])
          ]),
          _c("div", { staticClass: "search_card_price" }, [
            _c("span", [
              _c("span", { staticClass: "curr" }, [_vm._v("￥")]),
              _c("span", { staticClass: "price" }, [
                _vm._v(_vm._s(_vm.data.actualPrice))
              ])
            ]),
            _c("span", { staticClass: "origin_price" }, [
              _vm._v(" 原价￥" + _vm._s(_vm.data.originalPrice))
            ])
          ]),
          _c("div", { staticClass: "sales" }, [
            _vm._v(
              "已售" +
                _vm._s(_vm._f("salesNum")(_vm.data.monthSales, _vm._, 1)) +
                "件"
            )
          ]),
          _vm.data.couponPrice
            ? _c("div", { staticClass: "qiang" }, [
                _c("span", { staticClass: "qiang_q_p" }, [
                  _vm._v("领券直降"),
                  _c("b", [
                    _vm._v(_vm._s(_vm.data.couponPrice)),
                    _c("span", [_vm._v("元")])
                  ])
                ]),
                _c("span", { staticClass: "ljlq" }, [_vm._v("立即领券")])
              ])
            : _c("div", { staticClass: "ljqg" }, [_vm._v("立即抢购")])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }