var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "goodsHorizontal_container" }, [
    _c("img", {
      staticClass: "floor_banner",
      attrs: { src: _vm.background, alt: "", width: "100%" }
    }),
    _c(
      "div",
      { staticClass: "card_list" },
      [
        _vm._l(_vm.list, function(ite, index) {
          return [
            !ite.isAct
              ? _c("goods-horizontal-card", {
                  key: ite.id,
                  attrs: { data: ite, index: index }
                })
              : _c("horizontal-dc-act", {
                  key: ite.id,
                  attrs: { data: ite, index: index }
                })
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }