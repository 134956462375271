var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "goodsTab_container" },
    _vm._l(_vm.data, function(floorTabItems, floorTabIndex) {
      return _c(
        "div",
        { key: floorTabIndex },
        [
          floorTabIndex === 0
            ? [
                _c("img", {
                  attrs: { src: _vm.background, width: "100%", alt: "" }
                }),
                _c("div", { staticClass: "swiper" }, [
                  _c(
                    "div",
                    {
                      staticClass: "swiper-wrapper",
                      style: {
                        justifyContent:
                          _vm.data.length >= 6 ? "inherit" : "center",
                        alignItems: _vm.data.length >= 6 ? "inherit" : "center"
                      }
                    },
                    _vm._l(_vm.data, function(item, swiperIndex) {
                      return _c(
                        "div",
                        {
                          key: swiperIndex,
                          staticClass: "swiper-slide",
                          class: _vm.swiperActive == swiperIndex ? "act" : "",
                          on: {
                            click: function($event) {
                              return _vm.changeNav(swiperIndex)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item.title) + " ")]
                      )
                    }),
                    0
                  )
                ]),
                _vm._l(_vm.list, function(item, iteIndex) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: iteIndex === _vm.swiperActive,
                          expression: "iteIndex === swiperActive"
                        }
                      ],
                      key: iteIndex,
                      staticClass: "sales_vertical"
                    },
                    [
                      _vm._l(item.list.slice(0, 10), function(
                        tabItem,
                        tabIndex
                      ) {
                        return [
                          tabItem.isAct
                            ? _c("vertical-dc-act", {
                                key: tabItem.id,
                                attrs: { data: tabItem, index: tabIndex }
                              })
                            : _c("goods-vertical-card", {
                                key: tabItem.id,
                                attrs: { data: tabItem, index: tabIndex }
                              })
                        ]
                      })
                    ],
                    2
                  )
                })
              ]
            : _vm._e()
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }