<template>
  <div class="horizontal_card">
    <div
      :class="{ card: true, isLast: (index + 1) % 2 == 0 }"
      @click="toDetail(data.goodsId || data.id)"
    >
      <img v-lazy="`${data.mainPic}_310x310.jpg`" alt="商品主图" />
      <div class="card_right">
        <div>
          <div class="search_card_title">
            <span :style="{ verticalAlign: 'middle' }"
              ><img
                :src="getShopImg(data.haitao, data.tchaoshi, data.shopType)"
                alt=""
            /></span>
            <span>{{ data.dtitle || data.title }}</span>
          </div>
          <div class="lq_special_text" v-if="data.couponPrice">
            <span>领券立减{{ data.couponPrice }}元</span>
            <span
              class="sp_text"
              :title="data.specialText && data.specialText[0]"
              v-if="data.specialText && data.specialText[0]"
            >
              {{ data.specialText && data.specialText[0] }}
            </span>
          </div>
        </div>
        <div class="priceWrap">
          <div class="price">
            <span class="price_ac"
              >￥<b>{{ data.actualPrice }}</b
              >活动价</span
            >
            <span class="price_or">原价￥{{ data.originalPrice }}</span>
          </div>
          <div class="qiang_btn">
            <div class="qiang">马上抢</div>
            <div class="qianged">
              {{ data.monthSales | salesNum("w",1) }}人已抢
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    index:Number
  },
  methods: {
    getShopImg(haitao, tchaoshi, shopType) {
      if (haitao == 1) return require("../../../assets/search/prod_icon2.png");
      if (tchaoshi == 1)
        return require("../../../assets/search/prod_icon3.png");
      if (shopType == 1)
        return require("../../../assets/search/prod_icon1.png");
      return require("../../../assets/search/prod_icon1.png");
    },
    toDetail(id) {
      try {
        DTKsatc.eventStat({
          name: "moveColumnClickEvent",
          params: {
            desc: "运营配置栏目点击",
            columnId: this.$route.query.id,
            objectId: id,
          },
        });
      } catch (err) {}
      window.open(`?r=/l/d&id=${id}&from=dtk`, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.horizontal_card {
  .card.isLast {
    margin-right: 0;
  }
  .card {
    display: flex;
    background: #fff;
    border-radius: 12px;
    width: 594px;
    padding: 10px;
    box-sizing: border-box;
    margin-right: 12px;
    margin-bottom: 12px;
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
      .search_card_title span {
        color: #fe2e54 !important;
      }
    }
    img {
      width: 160px;
      height: 160px;
      border-radius: 10px;
    }
    .card_right {
      flex-grow: 1;
      display: flex;
      padding: 5px 0;
      box-sizing: border-box;
      margin-left: 16px;
      flex-direction: column;
      justify-content: space-between;
      padding-right: 19px;
      .search_card_title {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        span {
          // vertical-align: middle;
          margin-left: 2px;
          font-size: 15px;
          color: #333;
        }
        img {
          width: 14px;
          height: 14px;
          flex-shrink: 0;
          border-radius: 0;
          margin-right: 4px;
        }
      }
      .lq_special_text {
        display: flex;
        text-align: center;
        margin-top: 8px;
        span {
          width: 109px;
          height: 30px;
          line-height: 30px;
          background-image: linear-gradient(94deg, #fff6eb 0%, #ffefd3 100%);
          border-radius: 15px;
          font-size: 14px;
          color: #ff4d00;
        }
        span.sp_text {
          color: #ff0021;
          background-image: linear-gradient(90deg, #fff0f0 0%, #ffe4ed 100%);
          margin-left: 7px;
        }
      }
      .priceWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .price {
          .price_ac {
            font-size: 14px;
            color: #ed1c24;
            b {
              font-size: 30px;
              margin: 0 4px 0 0;
            }
          }
          .price_or {
            font-size: 14px;
            color: #ababab;
            margin-left: 11px;
          }
        }
        .qiang_btn {
          width: 120px;
          height: 50px;
          background-image: linear-gradient(90deg, #ff6a2c 0%, #ff0100 99%);
          border-radius: 5px;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .qiang {
            margin-bottom: 2px;
            font-size: 16px;
          }
          .qianged {
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>