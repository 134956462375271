<template>
  <div class="goodsHorizontal_container">
    <img class="floor_banner" :src="background" alt="" width="100%" />
    <div class="card_list">
      <template v-for="(ite, index) in list">
        <goods-horizontal-card
          v-if="!ite.isAct"
          :key="ite.id"
          :data="ite"
          :index="index"
        />
        <horizontal-dc-act v-else :key="ite.id" :data="ite" :index="index" />
      </template>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";
import goodsHorizontalCard from "./goodsHorizontalCard.vue";
import horizontalDcAct from "./horizontalDcAct.vue";
import qs from "qs";
export default {
  props: {
    config: Object,
    title: String,
    index: Number,
    background: String,
    floorNum: Number, //限制商品数量
  },
  components: {
    goodsHorizontalCard,
    horizontalDcAct,
  },
  computed: {
    ...mapGetters(["marketGroupConfig"]),
  },
  data() {
    return {
      list: [],
      load: false,
      page: {
        pageId: 1,
        pageSize: 15,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      if (this.config?.url) {
        let params = {
          ...this.page,
        };
        if (this.config.type == 2) {
          let arr = this.config.url.split("?");
          let domain = arr[0];
          let obj = qs.parse(arr[1]);
          request(domain, {
            method: "GET",
            isOpenApi: true,
            data: {
              ...params,
              ...obj,
            },
          }).then((res) => {
            if (res && res.code === 0) {
              this.list = res.data.list.slice(0, this.floorNum);
              this.load = true;
            }
          });
          return;
        } else {
          request(this.config.url, {
            method: "GET",
            isNoMid: true,
            data: {
              ...params,
            },
          }).then((res) => {
            if (res && res.code === 1) {
              this.list = res.data.list.slice(0, this.floorNum);
              this.load = true;
            }
          });
        }
      }
    },
    setActivity(state) {
      if (!state) {
        return false;
      }
      const activity = Object.keys(state);
      let newData = this.list.map((item) => {
        activity.forEach((a) => {
          if (item.marketGroup[0] == a && item.quanMLink > 0) {
            item.isAct = true;
            return false;
          } else {
            item.isAct = false;
          }
        });
        return item;
      });
      this.list = [...newData];
    },
  },
  watch: {
    load(state) {
      if (state) {
        this.setActivity(this.marketGroupConfig);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.goodsHorizontal_container {
  .floor_banner {
    margin-bottom: 25px;
  }
  .card_list {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>