<template>
  <div class="goodsTab_container">
    <div v-for="(floorTabItems, floorTabIndex) in data" :key="floorTabIndex">
      <template v-if="floorTabIndex === 0">
        <img :src="background" width="100%" alt="" />
        <div class="swiper">
          <div
            class="swiper-wrapper"
            :style="{
              justifyContent: data.length >= 6 ? 'inherit' : 'center',
              alignItems: data.length >= 6 ? 'inherit' : 'center',
            }"
          >
            <div
              class="swiper-slide"
              :class="swiperActive == swiperIndex ? 'act' : ''"
              v-for="(item, swiperIndex) in data"
              :key="swiperIndex"
              @click="changeNav(swiperIndex)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
        <div
          class="sales_vertical"
          v-for="(item, iteIndex) in list"
          :key="iteIndex"
          v-show="iteIndex === swiperActive"
        >
          <template v-for="(tabItem, tabIndex) in item.list.slice(0, 10)">
            <vertical-dc-act
              v-if="tabItem.isAct"
              :data="tabItem"
              :key="tabItem.id"
              :index="tabIndex"
            />
            <goods-vertical-card
              v-else
              :data="tabItem"
              :key="tabItem.id"
              :index="tabIndex"
            />
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import goodsVerticalCard from "./goodsVerticalCard.vue";
import verticalDcAct from "./verticalDcAct.vue";
import { importCDN } from "@/utils/utils.js";
import { parse } from "qs";
import { mapGetters } from "vuex";
import request from "@/utils/request";
export default {
  props: {
    data: Array,
    background: String,
  },
  components: {
    goodsVerticalCard,
    verticalDcAct,
  },
  computed: {
    ...mapGetters(["marketGroupConfig"]),
  },
  data() {
    return {
      swiperActive: 0,
      list: [],
      mySwiper: "",
      loading: true, //节流
    };
  },
  created() {
    this.list = this.data.map((item) => {
      item.list = [];
      item.page = {
        pageId: 1,
        pageSize: 15,
      };
      return item;
    });
  },
  mounted() {
    this.init();
    this.getList(0);
  },
  watch: {
    loading(state) {
      if (state) {
        this.setActivity(this.marketGroupConfig);
      }
    },
  },
  methods: {
    changeNav(item) {
      this.swiperActive = item;
      // this.mySwiper.slideTo(item - 2, 600, false);
      this.getList(item);
    },
    getList(index) {
      if (!this.loading) {
        return;
      }
      this.loading = false;
      this.swiperActive = index;
      let goodsItem = this.list[index];

      const body = {
        ...{
          pageId: goodsItem.page.pageId,
          pageSize: goodsItem.page.pageSize,
        },
      };
      let obj = { method: "GET", data: { ...body } };
      if (goodsItem.config.type == 2) {
        obj.isOpenApi = true;
        const items = parse(goodsItem.config.url.split("?")[1]);
        obj.data = { ...body, ...items };
        return request(goodsItem.config.url.split("?")[0], { ...obj }).then(
          (res) => {
            if (res && res.code == 0) {
              const newData = this.list.map((item, currIndex) => {
                if (currIndex == index) {
                  item.list = res.data.list;
                }
                return item;
              });
              this.list = newData;
              this.loading = true;
            }
          }
        );
      }
      request(goodsItem.config.url, { ...obj, isNoMid: true }).then((res) => {
        if (res && res.code === 1) {
          const newData = this.list.map((item, currIndex) => {
            if (index == currIndex) {
              item.list = res.data.list;
            }
            return item;
          });
          this.list = newData;
          this.loading = true;
        }
      });
    },
    init() {
      importCDN("https://public.ffquan.cn/lib/swiper/js/swiper.min.js").then(
        () => {
          this.mySwiper = new Swiper(".swiper", {
            freeMode: true,
            slidesPerView: "auto",
            initialSlide: 0,
            paginationClickable: true,
          });
        }
      );
    },
    setActivity(state) {
      if (!state) {
        return false;
      }
      const activity = Object.keys(state);
      this.list.forEach((item) => {
        item.list.map((c) => {
          activity.forEach((a) => {
            if (c.marketGroup[0] == a && c.quanMLink > 0) {
              c.isAct = true;
              return false
            } else {
              c.isAct = false;
            }
          });
          return c;
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.goodsTab_container {
  .swiper {
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    margin-bottom: 25px;
    overflow: hidden;
  }
  .swiper-wrapper {
    height: 55px;
    width: 100%;
    display: flex;
    // justify-content: center;
    // align-items: center;
    .swiper-slide {
      height: 31px;
      line-height: 31px;
      margin: auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      white-space: nowrap;
      font-size: 16px;
      margin-right: 76px;
      padding: 5px 24px;
      box-sizing: border-box;
      cursor: pointer;
      &:last-of-type {
        margin-right: 0;
      }
      &.act {
        background: #ed1c24;
        border-radius: 15.5px;
      }
    }
  }
  .sales_vertical {
    display: flex;
    flex-wrap: wrap;
    .search_card.isLast {
      margin-right: 0;
    }
    .search_card {
      border-radius: 12px;
      position: relative;
      text-decoration: none;
      width: 230px;
      background: #fff;
      flex-shrink: 0;
      margin-bottom: 12px;
      list-style: none;
      box-sizing: border-box;
      margin-right: 12px;
      cursor: pointer;
      &:hover {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        .search_card_title span {
          color: #fe2e54 !important;
        }
      }
      span.sp_text {
        max-width: 230px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 25px;
        top: 205px;
        line-height: 25px;
        padding: 0 12px;
        background-image: linear-gradient(270deg, #ff6e00 0%, #ff0000 100%);
        border-radius: 0 12px 0 0;
        overflow: hidden;
        position: absolute;
        left: 0;
        font-size: 14px;
        z-index: 99;
        color: #fff;
        box-sizing: border-box;
      }
      span.active_tag {
        width: 190px;
        height: 35px;
        top: 176px;
        text-align: right;
        line-height: 18px;
        padding: 17px 5px 0 0;
        background: url("https://img.alicdn.com/imgextra/i1/2053469401/O1CN013InyzO2JJi7taWujh_!!2053469401.png")
          0px 0px / contain no-repeat;
        position: absolute;
        left: 20px;
        font-size: 12px;
        color: #fff;
        box-sizing: border-box;
      }
      img.main_img {
        width: 230px;
        height: 230px;
        display: block;
        border-radius: 12px 12px 0 0;
      }
      .card_content {
        padding: 15px 10px;
        .search_card_title {
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-box-align: center;
          -webkit-line-clamp: 2;
          min-height: 38px;
          margin-top: 9px;
          span {
            // vertical-align: middle;
            margin-left: 2px;
            font-size: 14px;
            color: #333;
          }
          img {
            width: 14px;
            height: 14px;
            flex-shrink: 0;
          }
        }
        .search_card_tags {
          min-height: 16px;
          display: flex;
          align-items: center;
          margin-top: 9px;
          span.tag {
            height: 16px;
            background: #ffe8e7;
            border-radius: 2px;
            padding: 0 3px;
            font-size: 12px;
            color: #ff2828;
            line-height: 16px;
            margin-right: 5px;
          }
        }
        .search_card_price {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          align-items: center;
          margin-top: 9px;
          .curr {
            font-size: 14px;
            color: #ed1c24;
          }
          .price {
            font-size: 26px;
            color: #ed1c24;
            font-weight: bolder;
          }
          .origin_price {
            display: flex;
            font-size: 14px;
            color: #ababab;
          }
        }
        .sales {
          font-size: 14px;
          color: #ababab;
        }
        .qiang {
          width: 100%;
          height: 32px;
          line-height: 20px;
          background: url("../images/floor_bg.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: space-between;
          padding: 7px 10px;
          box-sizing: border-box;
          margin-top: 8px;
          .qiang_q_p {
            font-size: 13px;
            color: #ff4d00;
            b {
              font-weight: bolder;
              font-size: 18px;
              margin-left: 4px;
              span {
                font-size: 14px;
              }
            }
          }
          .ljlq {
            font-size: 13px;
            color: #fff;
          }
        }
        .ljqg {
          background: #f30505;
          border-radius: 5px;
          height: 32px;
          line-height: 33px;
          text-align: center;
          font-size: 13px;
          color: #ffffff;
          margin-top: 8px;
        }
      }
    }
  }
}
</style>