<template>
  <div class="salesdemo_container" :style="{ background: data.background }">
    <template v-for="item in data.floor">
      <div
        :key="item.id"
        v-if="item.type === 0 && (item.config.banner && item.config.banner.length)"
        class="salesdemo_banner"
      >
        <img v-if="item.config.banner.length" :src="item.config.banner[0] && item.config.banner[0].pic" alt="" />
      </div>
    </template>

    <div class="salesdemo_wrapper">
      <div class="ads_area" v-for="adsItem in data.ads" :key="adsItem.type">
        <div
          class="ads_first_floor"
          v-if="adsItem.type === 'ads_column_one' && adsItem.item.length"
        >
          <a
            v-jump="adsItem.item[adsItemIndex]"
            v-for="(adsFloorItem, adsItemIndex) in adsItem.item"
            :key="adsItemIndex"
            target="_blank"
            @click="handleMd"
          >
            <img :src="adsFloorItem.pc_ad_url" alt="" />
          </a>
        </div>
        <div
          class="ads_second_floor"
          v-if="adsItem.type === 'ads_column_two' && adsItem.item.length"
        >
          <a
            v-jump="adsItem.item[adsItemIndex]"
            v-for="(adsFloorItem, adsItemIndex) in adsItem.item"
            :key="adsItemIndex"
            target="_blank"
            @click="handleMd"
          >
            <img :src="adsFloorItem.pc_ad_url" alt="" />
          </a>
        </div>
        <div
          class="ads_third_floor"
          v-if="adsItem.type === 'ads_column_three' && adsItem.item.length"
        >
          <a
            v-jump="adsItem.item[adsItemIndex]"
            v-for="(adsFloorItem, adsItemIndex) in adsItem.item"
            :key="adsItemIndex"
            target="_blank"
            @click="handleMd"
          >
            <img :src="adsFloorItem.pc_ad_url" alt="" />
          </a>
        </div>
        <div
          class="ads_fourth_floor"
          v-if="adsItem.type === 'ads_column_four' && adsItem.item.length"
        >
          <a
            v-jump="adsItem.item[adsItemIndex]"
            v-for="(adsFloorItem, adsItemIndex) in adsItem.item"
            :key="adsItemIndex"
            target="_blank"
            @click="handleMd"
          >
            <img :src="adsFloorItem.pc_ad_url" alt="" />
          </a>
        </div>
      </div>
      <div
        v-for="(floorItem, floorIndex) in data.floor"
        class="floorArea"
        :key="floorIndex"
      >
        <!-- 楼层1 -->
        <div
          class="floor1"
          :id="'anchor-' + floorIndex"
          v-if="floorItem.type == 1"
        >
          <div class="floor1_goods">
            <GoodsVertical
              :background="floorItem.background"
              :config="floorItem.config"
              :index="floorIndex"
              :title="floorItem.title"
              :floorNum="5"
            />
          </div>
        </div>
        <!-- 楼层2 -->
        <div
          class="floor2"
          :id="'anchor-' + floorIndex"
          v-if="floorItem.type == 2"
        >
          <div class="floor2_goods">
            <GoodsHorizontal
              :background="floorItem.background"
              :config="floorItem.config"
              :title="floorItem.title"
              :floorNum="6"
            />
          </div>
        </div>
        <!-- 楼层3 -->
        <div
          class="floor3"
          :id="'anchor-' + floorIndex"
          v-if="floorItem.type == 3"
        >
          <div class="floor3_goods">
            <GoodsVertical
              :background="floorItem.background"
              :config="floorItem.config"
              :title="floorItem.title"
              :floorNum="10"
            />
          </div>
        </div>

        <!-- 楼层4 -->
        <div
          class="floor4"
          :id="'anchor-' + floorIndex"
          v-if="floorItem.type == 4"
        >
          <GoodsTabs
            :data="floorItem.config"
            :background="floorItem.background"
          />
        </div>
      </div>
    </div>

    <div class="scroll_top">
      <div v-if="data.style.pendant_img" class="scr_img">
        <img :src="data.style.pendant_img" alt="" />
      </div>
      <ul class="scr_nav">
        <li
          v-for="(scrollItem, scrollIndex) in data.floor"
          v-show="scrollIndex > 0"
          :class="`${scrollIndex == active ? 'cur' : ''}`"
          :key="scrollIndex"
          @click="handleScrNav(scrollIndex, `#anchor-${scrollIndex}`)"
        >
          {{ scrollItem.title }}
        </li>
      </ul>
      <div
        class="to_top"
        :style="{
          background: data.style.pendant_btn_bg,
          backgroundImage: `url(${data.style.pendant_btn_bg_url})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
        }"
        @click="handleToTop()"
        v-show="showReturnTop"
      >
        <img src="../images/up.png" alt="" />
        <div>回顶部</div>
      </div>
      <div v-if="data.style" style="display:none" v-html="`
      <style>
        .scroll_top .scr_nav > li{
            color:${data.style.pendant_btn_font_color};
            background-color:${data.style.pendant_btn_bg};
            background-image:${`url(${data.style.pendant_btn_bg_url})`};
            background-repeat: no-repeat;
            background-size: contain;
        }
        .scroll_top .scr_nav li:hover,.scroll_top .scr_nav li.cur{
          color:${data.style.pendant_btn_font_color_choose};
          background-color:${data.style.pendant_btn_bg_choose};
          background-image:${`url(${data.style.pendant_btn_bg_url_choose})`};
          background-repeat: no-repeat;
          background-size: contain;
        }
      </style>
      `"></div>
    </div>
  </div>
</template>

<script>
import GoodsVertical from "../components/goodsVertical.vue";
import GoodsHorizontal from "../components/goodsHorizontal.vue";
import GoodsTabs from "../components/goodsTabs.vue";

export default {
  props: {
    data: Object,
  },
  data() {
    return {
      showReturnTop: false,
      pageY: 400,
      active: null,
      isHover: false,
      hoverActive: null,
      adsBanner: 0,
      adsArea: 0,
      floorDom: "",
      scroll: true,
    };
  },
  components: {
    GoodsVertical,
    GoodsHorizontal,
    GoodsTabs,
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop.bind(this));
    window.addEventListener("scroll", this.handleScroll.bind(this));
    this.$nextTick(() => {
      this.floorDom = document.querySelector(".floorArea").offsetTop;
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    // renderStyle(style, scrollIndex) {
    //   const ret = {
    //     color:
    //       scrollIndex == this.active
    //         ? style.pendant_btn_font_color_choose
    //         : style.pendant_btn_font_color,
    //     backgroundColor:
    //       scrollIndex == this.active
    //         ? style.pendant_btn_bg_choose
    //         : style.pendant_btn_bg,
    //     backgroundImage:
    //       scrollIndex == this.active
    //         ? `url(${style.pendant_btn_bg_url_choose})`
    //         : `url(${style.pendant_btn_bg_url})`,
    //     backgroundRepeat: "no-repeat",
    //     backgroundSize: "contain",
    //   };
    //   return ret;
    // },
    // 广告位埋点
    handleMd() {
      try {
        DTKsatc.eventStat({
          name: "moveColumnClickEvent",
          params: {
            desc: "运营配置栏目点击",
            columnId: this.$route.query.id,
            objectId: "",
          },
        });
      } catch (err) {}
    },
    handleScrNav(item, selector) {
      this.scroll = false;
      if (selector) {
        try {
          window.scrollTo({
            top: document.querySelector(selector).offsetTop,
            behavior: "smooth",
          });
        } catch (error) {
         window.scrollTo(0, document.querySelector(selector).offsetTop);
        }
        
        setTimeout(() => {
          this.scroll = true;
        }, 2000);
      }
      this.active = item;
    },
    scrollToTop() {
      window.scrollY > this.pageY
        ? (this.showReturnTop = true)
        : (this.showReturnTop = false);
    },
    handleScroll() {
      if (!this.scroll) return;
      const scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      let arr = [];
      this.data.floor.forEach((i, index) => {
        let obj = {};
        obj.num =
          document.querySelector(`#anchor-${index}`) &&
          document.querySelector(`#anchor-${index}`).offsetTop;
        obj.index = index;
        arr.push(obj);
      });
      let newArr = arr.sort((a, b) => b.num - a.num);
      let is = false;
      let newIndex = 0;
      newArr.forEach((item, itemIndex) => {
        if (scrollTop >= item.num && !is) {
          is = true;
          newIndex = item.index;
        }
      });
      if (newIndex) {
        this.active = newIndex;
      }
      if (this.floorDom >= window.scrollY) {
        this.active = null;
      }
    },
    handleToTop() {
      try {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } catch (error) {
          window.scrollTo(0,0);     
      }
    },
  },
};
</script>

<style lang="less" scoped>
.salesdemo_container {
  min-height: calc(100vh - 335px);
  .salesdemo_banner {
    width: 100%;
    margin-bottom: 24px;
    img {
      width: 100%;
    }
  }
  .salesdemo_wrapper {
    width: 1200px;
    margin: 0 auto;
    .ads_area {
      .ads_first_floor {
        margin-bottom: 24px;
        a {
          img {
            width: 100%;
            display: block;
          }
        }
      }
      .ads_second_floor {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 24px;
        a {
          img {
            display: block;
            width: 600px;
          }
        }
      }
      .ads_third_floor {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 24px;
        a {
          img {
            display: block;
            width: 400px;
          }
        }
      }
      .ads_fourth_floor {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 60px;
        a {
          img {
            display: block;
            width: 300px;
          }
        }
      }
    }
    .floor4 {
      // .swiper {
      //   width: 100%;
      //   background: rgba(0, 0, 0, 0.5);
      //   border-radius: 12px;
      //   margin-bottom: 25px;
      //   overflow: hidden;
      // }
      // .swiper-wrapper {
      //   height: 55px;
      //   width: 100%;
      //   display: flex;
      //   // justify-content: center;
      //   // align-items: center;
      //   .swiper-slide {
      //     height: 31px;
      //     line-height: 31px;
      //     margin: auto 0;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //     color: #fff;
      //     white-space: nowrap;
      //     font-size: 16px;
      //     margin-right: 76px;
      //     padding: 5px 24px;
      //     box-sizing: border-box;
      //     cursor: pointer;
      //     &:last-of-type {
      //       margin-right: 0;
      //     }
      //     &.act {
      //       background: #ed1c24;
      //       border-radius: 15.5px;
      //     }
      //   }
      // }
    }
  }
  .scroll_top {
    position: fixed;
    bottom: 80px;
    right: 50%;
    width: 130px;
    z-index: 999;
    margin-right: -745px;
    box-sizing: border-box;
    border-radius: 12px;
    overflow: hidden;
    .scr_img {
      border-radius: 12px 12px 0 0;
      overflow: hidden;
      img {
        width: 130px;
        height: 130px;
        display: block;
        border-radius: 12px 12px 0 0;
      }
    }
    .scr_nav {
      text-align: center;
      border-radius: 12px 12px 0 0;
      > li {
        // color: #fff;
        font-size: 14px;
        line-height: 32px;
        height: 32px;
        position: relative;
        cursor: pointer;
        // &.cur {
        //   background-repeat: no-repeat;
        //   background-size: 100% auto;
        // }
        &.cur::after {
          content: "";
          width: 12px;
          height: 14px;
          background: url("../images/dw.png") no-repeat;
          background-size: contain;
          position: absolute;
          left: 6px;
          top: 9px;
        }
      }
    }
    .to_top {
      text-align: center;
      cursor: pointer;
      padding-bottom: 10px;
      img {
        width: 14px;
      }
      div {
        color: #fff;
        font-size: 14px;
        opacity: 0.7;
      }
    }
  }
}
</style>