<template>
  <div class="vertical_card">
    <div
      :class="{ search_card: true, isLast: (index + 1) % 5 == 0 }"
      @click="toDetail(data.goodsId || data.id)"
    >
      <span class="sp_text" v-if="data.quanMLink">
        定金{{ data.quanMLink }}{{ data.hzQuanOver ? `抵${diPrice}` : "" }}
      </span>
      <img
        class="main_img"
        :src="`${data.mainPic}_310x310.jpg`"
        alt="商品主图"
      />
      <div class="card_content">
        <div class="search_card_title">
          <span :style="{ verticalAlign: 'middle' }"
            ><img src="../images/ys_icon.png" alt=""
          /></span>
          <span>{{ data.dtitle || data.title }}</span>
        </div>
        <div class="search_card_price">
          <span>
            <span class="curr">￥</span>
            <span class="price">{{ data.actualPrice }}</span>
          </span>
          <span class="origin_price"> 原价￥{{ data.originalPrice }}</span>
        </div>
        <div class="sales">已定{{ data.monthSales | salesNum(_,1) }}件</div>
        <div v-if="data.couponPrice" class="qiang">
          <span class="qiang_q_p"
            >领券直降<b>{{ data.couponPrice }}<span>元</span></b></span
          >
          <span class="ljlq">立即付定</span>
        </div>
        <div v-else class="ljqg">立即付定</div>
      </div>
    </div>
  </div>
</template>

<script>
import { AccAdd } from "@/utils/utils";
export default {
  props: {
    data: Object,
    index: Number,
  },
  computed: {
    diPrice() {
      return AccAdd({
        num: [this.data.quanMLink, this.data.hzQuanOver || 0],
      });
    },
  },
  methods: {
    toDetail(id) {
      try {
        DTKsatc.eventStat({
          name: "moveColumnClickEvent",
          params: {
            desc: "运营配置栏目点击",
            columnId: this.$route.query.id,
            objectId: id,
          },
        });
      } catch (err) {}
      window.open(`?r=/l/d&id=${id}&from=dtk`, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.vertical_card {
  .search_card.isLast {
    margin-right: 0;
  }
  .search_card {
    border-radius: 12px;
    position: relative;
    text-decoration: none;
    width: 230px;
    background: #fff;
    flex-shrink: 0;
    margin-bottom: 12px;
    list-style: none;
    box-sizing: border-box;
    margin-right: 12px;
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
      .search_card_title span {
        color: #fe2e54 !important;
      }
    }
    span.sp_text {
      max-width: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 25px;
      top: 205px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 25px;
      padding: 0 12px;
      background: #e747ff;
      border-radius: 0 12px 0 0;
      overflow: hidden;
      position: absolute;
      left: 0;
      min-width: 100px;
      font-size: 14px;
      z-index: 1;
      color: #fff;
      box-sizing: border-box;
    }
    span.active_tag {
      width: 190px;
      height: 35px;
      top: 176px;
      text-align: right;
      line-height: 18px;
      padding: 17px 5px 0 0;
      background: url("https://img.alicdn.com/imgextra/i1/2053469401/O1CN013InyzO2JJi7taWujh_!!2053469401.png")
        0px 0px / contain no-repeat;
      position: absolute;
      left: 20px;
      font-size: 12px;
      color: #fff;
      box-sizing: border-box;
    }
    img.main_img {
      width: 230px;
      height: 230px;
      display: block;
      border-radius: 12px 12px 0 0;
    }
    .card_content {
      padding: 12px 10px;
      height: 37%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .search_card_title {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        min-height: 38px;
        span {
          // vertical-align: middle;
          margin-left: 2px;
          font-size: 14px;
          color: #333;
        }
        img {
          width: 30px;
          height: 17px;
          flex-shrink: 0;
          margin-right: 4px;
        }
      }
      .search_card_tags {
        min-height: 16px;
        display: flex;
        align-items: center;
        margin-top: 9px;
        span.tag {
          height: 16px;
          background: #ffe8e7;
          border-radius: 2px;
          padding: 0 3px;
          font-size: 12px;
          color: #ff2828;
          line-height: 16px;
          margin-right: 5px;
        }
      }
      .search_card_price {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        align-items: center;
        margin-top: 6px;
        .curr {
          font-size: 14px;
          color: #ed1c24;
        }
        .price {
          font-size: 26px;
          color: #ed1c24;
          font-weight: bolder;
        }
        .origin_price {
          display: flex;
          font-size: 14px;
          color: #ababab;
        }
      }
      .sales {
        font-size: 14px;
        color: #772EE2;
      }
      .qiang {
        width: 100%;
        height: 32px;
        line-height: 20px;
        background: url("../images/floor_act_bg.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: space-between;
        padding: 7px 10px;
        box-sizing: border-box;
        margin-top: 8px;
        .qiang_q_p {
          font-size: 13px;
          color: #C300BD;
          b {
            font-weight: bolder;
            font-size: 18px;
            margin-left: 4px;
            span {
              font-size: 14px;
            }
          }
        }
        .ljlq {
          font-size: 13px;
          color: #fff;
        }
      }
      .ljqg {
        background: #E747FF;
        border-radius: 5px;
        height: 32px;
        line-height: 33px;
        text-align: center;
        font-size: 13px;
        color: #ffffff;
        margin-top: 8px;
      }
    }
  }
}
</style>