var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "horizontal_card" }, [
    _c(
      "div",
      {
        class: { card: true, isLast: (_vm.index + 1) % 2 == 0 },
        on: {
          click: function($event) {
            return _vm.toDetail(_vm.data.goodsId || _vm.data.id)
          }
        }
      },
      [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value: _vm.data.mainPic + "_310x310.jpg",
              expression: "`${data.mainPic}_310x310.jpg`"
            }
          ],
          attrs: { alt: "商品主图" }
        }),
        _c("div", { staticClass: "card_right" }, [
          _c("div", [
            _c("div", { staticClass: "search_card_title" }, [
              _c("span", { style: { verticalAlign: "middle" } }, [
                _c("img", {
                  attrs: {
                    src: _vm.getShopImg(
                      _vm.data.haitao,
                      _vm.data.tchaoshi,
                      _vm.data.shopType
                    ),
                    alt: ""
                  }
                })
              ]),
              _c("span", [_vm._v(_vm._s(_vm.data.dtitle || _vm.data.title))])
            ]),
            _vm.data.couponPrice
              ? _c("div", { staticClass: "lq_special_text" }, [
                  _c("span", [
                    _vm._v("领券立减" + _vm._s(_vm.data.couponPrice) + "元")
                  ]),
                  _vm.data.specialText && _vm.data.specialText[0]
                    ? _c(
                        "span",
                        {
                          staticClass: "sp_text",
                          attrs: {
                            title:
                              _vm.data.specialText && _vm.data.specialText[0]
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.data.specialText && _vm.data.specialText[0]
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "priceWrap" }, [
            _c("div", { staticClass: "price" }, [
              _c("span", { staticClass: "price_ac" }, [
                _vm._v("￥"),
                _c("b", [_vm._v(_vm._s(_vm.data.actualPrice))]),
                _vm._v("活动价")
              ]),
              _c("span", { staticClass: "price_or" }, [
                _vm._v("原价￥" + _vm._s(_vm.data.originalPrice))
              ])
            ]),
            _c("div", { staticClass: "qiang_btn" }, [
              _c("div", { staticClass: "qiang" }, [_vm._v("马上抢")]),
              _c("div", { staticClass: "qianged" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("salesNum")(_vm.data.monthSales, "w", 1)) +
                    "人已抢 "
                )
              ])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }