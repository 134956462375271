var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "salesdemo_container",
      style: { background: _vm.data.background }
    },
    [
      _vm._l(_vm.data.floor, function(item) {
        return [
          item.type === 0 && item.config.banner && item.config.banner.length
            ? _c("div", { key: item.id, staticClass: "salesdemo_banner" }, [
                item.config.banner.length
                  ? _c("img", {
                      attrs: {
                        src: item.config.banner[0] && item.config.banner[0].pic,
                        alt: ""
                      }
                    })
                  : _vm._e()
              ])
            : _vm._e()
        ]
      }),
      _c(
        "div",
        { staticClass: "salesdemo_wrapper" },
        [
          _vm._l(_vm.data.ads, function(adsItem) {
            return _c("div", { key: adsItem.type, staticClass: "ads_area" }, [
              adsItem.type === "ads_column_one" && adsItem.item.length
                ? _c(
                    "div",
                    { staticClass: "ads_first_floor" },
                    _vm._l(adsItem.item, function(adsFloorItem, adsItemIndex) {
                      return _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "jump",
                              rawName: "v-jump",
                              value: adsItem.item[adsItemIndex],
                              expression: "adsItem.item[adsItemIndex]"
                            }
                          ],
                          key: adsItemIndex,
                          attrs: { target: "_blank" },
                          on: { click: _vm.handleMd }
                        },
                        [
                          _c("img", {
                            attrs: { src: adsFloorItem.pc_ad_url, alt: "" }
                          })
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              adsItem.type === "ads_column_two" && adsItem.item.length
                ? _c(
                    "div",
                    { staticClass: "ads_second_floor" },
                    _vm._l(adsItem.item, function(adsFloorItem, adsItemIndex) {
                      return _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "jump",
                              rawName: "v-jump",
                              value: adsItem.item[adsItemIndex],
                              expression: "adsItem.item[adsItemIndex]"
                            }
                          ],
                          key: adsItemIndex,
                          attrs: { target: "_blank" },
                          on: { click: _vm.handleMd }
                        },
                        [
                          _c("img", {
                            attrs: { src: adsFloorItem.pc_ad_url, alt: "" }
                          })
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              adsItem.type === "ads_column_three" && adsItem.item.length
                ? _c(
                    "div",
                    { staticClass: "ads_third_floor" },
                    _vm._l(adsItem.item, function(adsFloorItem, adsItemIndex) {
                      return _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "jump",
                              rawName: "v-jump",
                              value: adsItem.item[adsItemIndex],
                              expression: "adsItem.item[adsItemIndex]"
                            }
                          ],
                          key: adsItemIndex,
                          attrs: { target: "_blank" },
                          on: { click: _vm.handleMd }
                        },
                        [
                          _c("img", {
                            attrs: { src: adsFloorItem.pc_ad_url, alt: "" }
                          })
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              adsItem.type === "ads_column_four" && adsItem.item.length
                ? _c(
                    "div",
                    { staticClass: "ads_fourth_floor" },
                    _vm._l(adsItem.item, function(adsFloorItem, adsItemIndex) {
                      return _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "jump",
                              rawName: "v-jump",
                              value: adsItem.item[adsItemIndex],
                              expression: "adsItem.item[adsItemIndex]"
                            }
                          ],
                          key: adsItemIndex,
                          attrs: { target: "_blank" },
                          on: { click: _vm.handleMd }
                        },
                        [
                          _c("img", {
                            attrs: { src: adsFloorItem.pc_ad_url, alt: "" }
                          })
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ])
          }),
          _vm._l(_vm.data.floor, function(floorItem, floorIndex) {
            return _c("div", { key: floorIndex, staticClass: "floorArea" }, [
              floorItem.type == 1
                ? _c(
                    "div",
                    {
                      staticClass: "floor1",
                      attrs: { id: "anchor-" + floorIndex }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "floor1_goods" },
                        [
                          _c("GoodsVertical", {
                            attrs: {
                              background: floorItem.background,
                              config: floorItem.config,
                              index: floorIndex,
                              title: floorItem.title,
                              floorNum: 5
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              floorItem.type == 2
                ? _c(
                    "div",
                    {
                      staticClass: "floor2",
                      attrs: { id: "anchor-" + floorIndex }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "floor2_goods" },
                        [
                          _c("GoodsHorizontal", {
                            attrs: {
                              background: floorItem.background,
                              config: floorItem.config,
                              title: floorItem.title,
                              floorNum: 6
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              floorItem.type == 3
                ? _c(
                    "div",
                    {
                      staticClass: "floor3",
                      attrs: { id: "anchor-" + floorIndex }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "floor3_goods" },
                        [
                          _c("GoodsVertical", {
                            attrs: {
                              background: floorItem.background,
                              config: floorItem.config,
                              title: floorItem.title,
                              floorNum: 10
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              floorItem.type == 4
                ? _c(
                    "div",
                    {
                      staticClass: "floor4",
                      attrs: { id: "anchor-" + floorIndex }
                    },
                    [
                      _c("GoodsTabs", {
                        attrs: {
                          data: floorItem.config,
                          background: floorItem.background
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          })
        ],
        2
      ),
      _c("div", { staticClass: "scroll_top" }, [
        _vm.data.style.pendant_img
          ? _c("div", { staticClass: "scr_img" }, [
              _c("img", { attrs: { src: _vm.data.style.pendant_img, alt: "" } })
            ])
          : _vm._e(),
        _c(
          "ul",
          { staticClass: "scr_nav" },
          _vm._l(_vm.data.floor, function(scrollItem, scrollIndex) {
            return _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: scrollIndex > 0,
                    expression: "scrollIndex > 0"
                  }
                ],
                key: scrollIndex,
                class: "" + (scrollIndex == _vm.active ? "cur" : ""),
                on: {
                  click: function($event) {
                    return _vm.handleScrNav(
                      scrollIndex,
                      "#anchor-" + scrollIndex
                    )
                  }
                }
              },
              [_vm._v(" " + _vm._s(scrollItem.title) + " ")]
            )
          }),
          0
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showReturnTop,
                expression: "showReturnTop"
              }
            ],
            staticClass: "to_top",
            style: {
              background: _vm.data.style.pendant_btn_bg,
              backgroundImage: "url(" + _vm.data.style.pendant_btn_bg_url + ")",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%"
            },
            on: {
              click: function($event) {
                return _vm.handleToTop()
              }
            }
          },
          [
            _c("img", { attrs: { src: require("../images/up.png"), alt: "" } }),
            _c("div", [_vm._v("回顶部")])
          ]
        ),
        _vm.data.style
          ? _c("div", {
              staticStyle: { display: "none" },
              domProps: {
                innerHTML: _vm._s(
                  "\n    <style>\n      .scroll_top .scr_nav > li{\n          color:" +
                    _vm.data.style.pendant_btn_font_color +
                    ";\n          background-color:" +
                    _vm.data.style.pendant_btn_bg +
                    ";\n          background-image:" +
                    ("url(" + _vm.data.style.pendant_btn_bg_url + ")") +
                    ";\n          background-repeat: no-repeat;\n          background-size: contain;\n      }\n      .scroll_top .scr_nav li:hover,.scroll_top .scr_nav li.cur{\n        color:" +
                    _vm.data.style.pendant_btn_font_color_choose +
                    ";\n        background-color:" +
                    _vm.data.style.pendant_btn_bg_choose +
                    ";\n        background-image:" +
                    ("url(" + _vm.data.style.pendant_btn_bg_url_choose + ")") +
                    ";\n        background-repeat: no-repeat;\n        background-size: contain;\n      }\n    </style>\n    "
                )
              }
            })
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }